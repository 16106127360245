import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {DataTable, DataTableAsync, DataTablePaginated, FakeRoute} = _components;
  if (!DataTable) _missingMdxReference("DataTable", true);
  if (!DataTableAsync) _missingMdxReference("DataTableAsync", true);
  if (!DataTablePaginated) _missingMdxReference("DataTablePaginated", true);
  if (!FakeRoute) _missingMdxReference("FakeRoute", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import DataTable from \"@/shared/DataTable.jsx\";\nimport { DataTablePaginated } from \"@/shared/DataTable.jsx\";\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "A data table consists of 2 arrays"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "// Columns, used in the table header to allow for sorting\nconst cols = [\n  { display: \"name\" },\n  { display: \"Win Rate\" }\n];\n\n// Rows, an array of rows with an array of cells\nconst rows = [\n  [\n    { display: \"Rio\", value: \"rio\" },\n    { display: \"58.7%\", value: 0.587 }\n  ],\n  [\n    { display: \"Dali\", value: \"dali\" },\n    { display: \"51.5%\", value: 0.514 }\n  ],\n]\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Note: The columns indexes map to the row cells indexes. Therefore ", _jsx(_components.code, {
        children: "cols.length === rows[0].length"
      })]
    }), "\n", _jsx(_components.h3, {
      id: "loading-state",
      children: _jsx(_components.a, {
        href: "#loading-state",
        children: "Loading State"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If data is loading, ", _jsx(_components.code, {
        children: "rows"
      }), " should be ", _jsx(_components.code, {
        children: "undefined | null"
      }), ", this will automatically show a loading state."]
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<DataTable
  loadingRows={5} // Optional to control the height
  cols={[
    { display: "name" },
    { display: "Win Rate" }
  ]}
  rows={null}
/>`,
      children: _jsx(DataTable, {
        loadingRows: 5,
        cols: [{
          display: "name"
        }, {
          display: "Win Rate"
        }],
        rows: null
      })
    }), "\n", _jsx(_components.h3, {
      id: "no-results-state",
      children: _jsx(_components.a, {
        href: "#no-results-state",
        children: "No Results State"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If the ", _jsx(_components.code, {
        children: "rows"
      }), " array is empty (", _jsx(_components.code, {
        children: "[]"
      }), "), either due to no data being available or due to applied filters, it automatically shows a no result state."]
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<DataTable
  loadingRows={5} // Optional to control the height
  cols={[
    { display: "Name" },
    { display: "Win Rate" }
  ]}
  rows={[]}
  noResultsDescription={["", "You can set any no results description here"]}
  noResultsReturnLink={"/docs/component-data-table"}
  noResultsReturnText={["", "Return To Docs"]}
/>`,
      children: _jsx(DataTable, {
        loadingRows: 5,
        cols: [{
          display: "Name"
        }, {
          display: "Win Rate"
        }],
        rows: [],
        noResultsDescription: ["", "You can set any no results description here"],
        noResultsReturnLink: "/docs/component-data-table",
        noResultsReturnText: ["", "Return To Docs"]
      })
    }), "\n", _jsx(_components.h2, {
      id: "table-props",
      children: _jsx(_components.a, {
        href: "#table-props",
        children: "Table Props"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type TableProps = {\n  cols: Column[];\n  rows: undefined | null | Cell[][];\n  tallRows?: boolean; /// Some designs call for a slightly taller row (default 44px, tall 60)\n  indexCol?: boolean; /// Insert a column at the front for indexing\n  indexColTitle?: string; /// The header title for the above index column. Default is \"Rank\"\n  searchText?: string; /// Text to filter the rows by\n  searchCol?: number; /// What column index should be searched? (note: the .value is searched)\n  sortCol: number; /// What is the default sort column (if any)\n  sortColTiebreak?: number; /// What is the default tie-break sort column (if any)\n  sortDir?: \"DESC\" | \"ASC\"; /// What is the default sort direction (default \"DESC\")\n  className?: string; /// Class name used on the container of the table\n  classNameOuter?: string; /// Class name applied to the outer card of the table (note: this rarely needs to be used)\n  style?: React.CSSProperties;\n  loadingRows?: number; // Optional to control the height of table while loading\n  noResultsTitle?: Translation; // Optional to override the default no results title\n  noResultsDescription?: Translation; // Optional to override the default no results description\n  noResultsReturnLink?: string; // Optional to set the no results return link\n  noResultsReturnText?: Translation; // Optional to display and set the no results return text\n  borderless:? boolean; // Optional to remove the border/background from the table so you can place it inside a card or whatever\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "column-props",
      children: _jsx(_components.a, {
        href: "#column-props",
        children: "Column Props"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type Column = {\n  display: string | React.ReactNode;\n  tooltip?: string;\n  align?: string; /// \"center\" | \"left\" | \"right\"\n  primary?: boolean;\n  isStat?: boolean;\n};\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "cell-props",
      children: _jsx(_components.a, {
        href: "#cell-props",
        children: "Cell Props"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type Cell = {\n  display: number | string | React.ReactNode; /// What is rendered in the table\n  value: number | string; /// The value that is sorted/searched\n  className?: string; /// A class name for the cell for styling from the table's class name\n  style?: React.CSSProperties;\n  link?: string; /// Turns the cell into a link\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "example-table",
      children: _jsx(_components.a, {
        href: "#example-table",
        children: "Example Table"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<DataTable
  cols={[
    { display: "name" },
    { display: "Win Rate" },
    { display: "Wins" },
    { display: "losses" },
    { display: "Games" }
  ]}
  rows={[
    [
      { display: "Rio", value: "rio" },
      { display: "81.8%", value: 0.8125 },
      { display: 13, value: 13 },
      { display: 3, value: 3 },
      { display: 16, value: 16 }
    ],
    [
      { display: "Dali", value: "dali" },
      { display: "78.3%", value: 0.7826 },
      { display: 18, value: 18 },
      { display: 5, value: 5 },
      { display: 23, value: 23 }
    ]
  ]}
  indexCol
  className="meat-slicer"
  sortCol={1} // Win Rate
  sortColTiebreak={4} // Games
  sortDir="DESC"
  searchText={null}
  searchCol={0}
/>`,
      children: _jsx(DataTable, {
        cols: [{
          display: "name"
        }, {
          display: "Win Rate"
        }, {
          display: "Wins"
        }, {
          display: "losses"
        }, {
          display: "Games"
        }],
        rows: [[{
          display: "Rio",
          value: "rio"
        }, {
          display: "81.8%",
          value: 0.8125
        }, {
          display: 13,
          value: 13
        }, {
          display: 3,
          value: 3
        }, {
          display: 16,
          value: 16
        }], [{
          display: "Dali",
          value: "dali"
        }, {
          display: "78.3%",
          value: 0.7826
        }, {
          display: 18,
          value: 18
        }, {
          display: 5,
          value: 5
        }, {
          display: 23,
          value: 23
        }]],
        indexCol: true,
        className: "meat-slicer",
        sortCol: 1,
        sortColTiebreak: 4,
        sortDir: "DESC",
        searchText: null,
        searchCol: 0
      })
    }), "\n", _jsx(_components.h1, {
      id: "paginated-table",
      children: _jsx(_components.a, {
        href: "#paginated-table",
        children: "Paginated Table"
      })
    }), "\n", _jsx(_components.h2, {
      id: "table-props-1",
      children: _jsx(_components.a, {
        href: "#table-props-1",
        children: "Table Props"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type PagedTableProps = {\n  pageSize: number; /// loadingRows defaults to this as well\n  onPage(): void; /// Called when the table is paged\n} & TableProps;\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "example-table-1",
      children: _jsx(_components.a, {
        href: "#example-table-1",
        children: "Example Table"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      hideSource: true,
      className: "language-jsx",
      code: `<DataTablePaginated
  cols={[
    { display: "name" },
    { display: "Win Rate" },
    { display: "Wins" },
    { display: "losses" },
    { display: "Games" }
  ]}
  rows={[
    [
      { display: "Rio", value: "rio" },
      { display: "81.8%", value: 0.8125 },
      { display: 13, value: 13 },
      { display: 3, value: 3 },
      { display: 16, value: 16 }
    ],
    [
      { display: "Dali", value: "dali" },
      { display: "78.3%", value: 0.7826 },
      { display: 18, value: 18 },
      { display: 5, value: 5 },
      { display: 23, value: 23 }
    ],
    [
      { display: "Evil Rio", value: "evil rio" },
      { display: "71.8%", value: 0.7125 },
      { display: 11, value: 11 },
      { display: 3, value: 3 },
      { display: 14, value: 14 }
    ],
    [
      { display: "Evil Dali", value: "evil dali" },
      { display: "68.3%", value: 0.6826 },
      { display: 16, value: 16 },
      { display: 5, value: 5 },
      { display: 21, value: 21 }
    ]
  ]}
  indexCol
  className="meat-slicer"
  sortCol={1} // Win Rate
  sortColTiebreak={4} // Games
  sortDir="DESC"
  pageSize={2}
/>`,
      children: _jsx(DataTablePaginated, {
        cols: [{
          display: "name"
        }, {
          display: "Win Rate"
        }, {
          display: "Wins"
        }, {
          display: "losses"
        }, {
          display: "Games"
        }],
        rows: [[{
          display: "Rio",
          value: "rio"
        }, {
          display: "81.8%",
          value: 0.8125
        }, {
          display: 13,
          value: 13
        }, {
          display: 3,
          value: 3
        }, {
          display: 16,
          value: 16
        }], [{
          display: "Dali",
          value: "dali"
        }, {
          display: "78.3%",
          value: 0.7826
        }, {
          display: 18,
          value: 18
        }, {
          display: 5,
          value: 5
        }, {
          display: 23,
          value: 23
        }], [{
          display: "Evil Rio",
          value: "evil rio"
        }, {
          display: "71.8%",
          value: 0.7125
        }, {
          display: 11,
          value: 11
        }, {
          display: 3,
          value: 3
        }, {
          display: 14,
          value: 14
        }], [{
          display: "Evil Dali",
          value: "evil dali"
        }, {
          display: "68.3%",
          value: 0.6826
        }, {
          display: 16,
          value: 16
        }, {
          display: 5,
          value: 5
        }, {
          display: 21,
          value: 21
        }]],
        indexCol: true,
        className: "meat-slicer",
        sortCol: 1,
        sortColTiebreak: 4,
        sortDir: "DESC",
        pageSize: 2
      })
    }), "\n", _jsx(_components.h1, {
      id: "datatableasync",
      children: _jsx(_components.a, {
        href: "#datatableasync",
        children: "DataTableAsync"
      })
    }), "\n", _jsx(_components.h2, {
      id: "table-props-2",
      children: _jsx(_components.a, {
        href: "#table-props-2",
        children: "Table Props"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type AsyncTable = {\n  onMoreRows(req: RowsRequest): void;\n  totalRows: number;\n  pageSize: number;\n} & TableProps;\n\ntype RowsRequest = {\n  startRow: number;\n  endRow: number;\n  searchText?: string;\n  sortCol: number;\n  sortDir: SORT_DIR;\n};\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "example-table-2",
      children: _jsx(_components.a, {
        href: "#example-table-2",
        children: "Example Table"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      hideSource: true,
      className: "language-jsx",
      code: `<FakeRoute>
  {({
    rows,
    totalRows,
    debouncedUpdateRoute,
    columns,
  }) => {
      const handleMoreRows = ({
        startRow,
        endRow,
        searchText,
        sortCol,
        sortDir,
      }) => {
        debouncedUpdateRoute({}, {}, { transient: {startRow, endRow, searchText, sortCol, sortDir} });
      }
      return (
        <DataTableAsync
          cols={columns}
          rows={rows}
          totalRows={totalRows}
          onMoreRows={handleMoreRows}
          indexCol
          className="meat-slicer"
          sortCol={1}
          sortDir="DESC"
          pageSize={5}
        />
    )
  }}
</FakeRoute>`,
      children: _jsx(FakeRoute, {
        children: ({rows, totalRows, debouncedUpdateRoute, columns}) => {
          const handleMoreRows = ({startRow, endRow, searchText, sortCol, sortDir}) => {
            debouncedUpdateRoute({}, {}, {
              transient: {
                startRow,
                endRow,
                searchText,
                sortCol,
                sortDir
              }
            });
          };
          return _jsx(DataTableAsync, {
            cols: columns,
            rows: rows,
            totalRows: totalRows,
            onMoreRows: handleMoreRows,
            indexCol: true,
            className: "meat-slicer",
            sortCol: 1,
            sortDir: "DESC",
            pageSize: 5
          });
        }
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
